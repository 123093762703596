import { inject } from '@angular/core';
import { BooktechAppService } from './booktech-app.service';
import { MiscUtil } from '../util/misc.util';
import { PriceRule } from '../util/const';
import { FilterPipe } from '../pipes/ngx-filter.pipe';

import { DATAID } from './data.service';

export class OrderService {

  filter = new FilterPipe();
  constructor(public bas: BooktechAppService ) {
    if(this.bas.envtest) console.log("OrderService.constructor");

   
  }

  
  placeOrder(cart:any, extraParams:any, jsonData?:any):Promise<any> {
    
    var user = this.bas.ds.login.user;
    var params:any = { 
      actionType: "appService", 
      action: "placeOrder", 
      acceptEula: true
    };

    if (user) params.userId = user.id;

    MiscUtil.extend(params, extraParams);

    if (jsonData) params.jsonData = JSON.stringify(jsonData);


    MiscUtil.extend(params, MiscUtil.getCartParams(cart)); 

    if (this.bas.envtest) console.log("OrderService.placeOrder: params: ", params);

    return this.bas.ws.json(params, { showLoading: true }).then((json) => {
      if (this.bas.envtest) console.log("OrderService.placeOrder.then, ", json);
      
      if (json.success) {

        

      }

      return json;
    });
  }


  easyPay(id:number, payType:string, extraParams:any = { }):Promise<any> {
    var params:any = { 
      actionType: "order", 
      action: "easyPay", 
      id: id,
      payType: payType,
      url: this.bas.nossr ? location.href : ""
    };

    MiscUtil.extend(params, extraParams);

    if (this.bas.envtest) console.log("OrderService.easyPay: params: ", params);

    return this.bas.ws.json(params, { showLoading: true }).then((json) => {
      if (this.bas.envtest) console.log("OrderService.easyPay.then, ", json);
      
      if (json.success) {

        

      }

      return json;
    });
  }



  updatePwAmount (pw:any) {
    let priceRule = pw.priceRule || "PriceCount";  // PriceCountDaysGuests

    let start = this.bas.ui.dateParseIso(pw.startAsIso8601);
    let end = this.bas.ui.dateParseIso(pw.endAsIso8601);
    
    // if (this.bas.envtest) console.log("OrderService.updatePwAmount start, " + pw.startAsIso8601 + " -> " + start);
    // if (this.bas.envtest) console.log("OrderService.updatePwAmount end, " + pw.endAsIso8601 + " -> " + end);


    //static getPrice (priceRuleId:number|string, amountDouble:number, quantity:number, days?:number, start?:Date, end?:Date, guests?:number)
    let amount = MiscUtil.getPrice(priceRule, pw.unitAmount, pw.quantity, 1, start, end, pw.guestCounts?.count); // Setter days til 1 slik at vi ikke regner ut antall dager
    // if (this.bas.envtest) console.log("OrderService.updatePwAmount.amount, " + amount);
    if (amount === undefined) {

      return;
    }
    pw.amountDouble = amount;

  }

  getCart(cart:any = { }) {
    let jc = {
      items: this.getCartItems(cart.items)
    };

    return jc;
  }

  getCartItems(items:any[] = []) {
    // let items:any[] = cart?.items || [];
    return items.map((pw:any) => {
     
      let item:any = this.getPwParams(pw);
      MiscUtil.extend(item, {
        jsonData: pw.jsonData || { }
      })

      if (pw.accessories) {
        item.jsonData.accessories = { };
        pw.accessories.filter((acc:any) => !acc.findMandatory && acc.quantity).map((acc:any) => {
          item.jsonData.accessories[acc.productId] = {
            productId: acc.productId,
            quantity: acc.quantity
          }
        });
      }


      return item;
    }); 

  }


  updateAmount(pw:any) {
    let amount = pw.amountDouble;
    for (let acc of pw.accessoriesOptional) {
      amount += acc.amountDouble;
    }

    if (pw.groupItems?.length) {
      for (let gi of pw.groupItems) {
        amount += gi.amountDouble;
      }
    }
    if(this.bas.envtest) console.log("OrderService.updateAmount, setting calcAmount: " + pw.calcAmount + " -> " +  amount);
    pw.calcAmount = amount;
  }


  async getPwData(pw:any, gets:string[], opts:any = { }) {


    var params:any = {
      aType: "order",
      action: "getPwData",
      gets: gets.join(",")
    }

    if (opts?.params) {
      MiscUtil.extend(params, opts?.params)
    }

    MiscUtil.extend(params, this.getPwParams(pw));

    let res = await this.bas.ws.json(params, { });

    if (res.success) {
      // if(this.bas.envtest) console.log("OrderService.getPwAmount, setting pw.amount: " + pw.amount);

      for (let g of gets) {
        switch (g) {
          case "amount": 

            pw.amountInt =  res.amount;
            pw.amount = res.amountAsDouble;
            break;

          case "availability": 
            //TODO
   
          break;
          case "timeItemsAvailable": 
    
            pw.timeItemsAvailable = res.timeItemsAvailable;
   
            break;
        }
      }

    }


    if(this.bas.envtest) console.log("OrderService.getPwData, pw: ", pw);

    return pw;

  }

  async getPwAmount(pw:any, opts:any = { }) {

    // TODO: avgjøre om vi kan beregen det lokalt eller må spørre serveren. 

    let calcLocal = false; 

    let unitAmount = pw.unitAmount;

    if (unitAmount === undefined) calcLocal = false; 
    if (pw.quantity === 0) calcLocal = true; 

    if (calcLocal) {


      let gcs = pw.guestCounts;

      let qty =  pw.quantity != 0 ? (pw.productObj?.guestsIsCount ?  gcs.count : pw.quantitySelected) : 0.0;

      //TODO: sjekke unitAmountGcMap osv-

      let amount = MiscUtil.getPrice(pw.priceRule, unitAmount, qty, undefined, pw.periodArray?.[0], pw.periodArray?.[1], gcs.count);

      pw.amount = amount;


    } else {

      var params:any = {
        aType: "order",
        action: "getPwData",
        gets: "amount,obj"
      }
  
      if (opts?.params) {
        MiscUtil.extend(params, opts?.params)
      }
  
      MiscUtil.extend(params, this.getPwParams(pw));

      let res = await this.bas.ws.json(params, { });

      if (res.success) {
        // if(this.bas.envtest) console.log("OrderService.getPwAmount, setting pw.amount: " + pw.amount);

        pw.amountInt =  res.amount;
        pw.amount = res.amountAsDouble;
      }
 
    }

    if(this.bas.envtest) console.log("OrderService.getPwAmount, returning pw.amount: " + pw.amount);

    return pw.amount;

  }
    
  async updatePw(pw:any, opts:any = { }) {


    // let pw = this.pw ;
    if(this.bas.envtest) console.log("OrderService.updatePw, pw.input: ", pw);


    let res:any = undefined;
    if (opts.pwNext ) {
      let pwNext = this.updatePwFromPwValues( opts.pwNext )
      res = { success: true, cart: { items: [ pwNext  ] } } ;
    } else {
      var params:any = {
        aType: "appService",
        action: "getPwCart",
        product: pw.productId
      }
  
      if (opts?.params) {
        MiscUtil.extend(params, opts?.params)
      }
  
      MiscUtil.extend(params, this.getPwParams(pw));
  
   
     
      res = await this.bas.ws.json(params, { });
    }



    let pwPrev = pw;
    res.prev  = pwPrev;

    if(this.bas.envtest) console.log("OrderService.updatePw.res: ", res);
    if (res.success) {
      // this.cart = res.cart;

  
      pw = opts.pwNext || res.cart.items[0];

      if(this.bas.envtest) console.log("OrderService.updatePw.success.pw: ", pw);
      this.updatePwObj(pw);
      
      this.updatePwFromPrev(pw, pwPrev)


      pw = this.updatePwError(pw);


 
      res.pw = pw;
      return res;
    }

    // Feilmelding
    res.pw = pw;
    return res;
  }


  getPwParams(pw:any) {

    if (!pw) return pw;

    var params:any = {
      product: pw.productId
    }

    if (pw.productObj) {
      let p = pw.productObj;
      let singleDateProduct = (p.tcDate && p.singleDateProduct);
      if (pw.productObj.tcTime || singleDateProduct) {
        if (pw.startDateAsString) params.startDateAsString = pw.startDateAsString;
        if (singleDateProduct) params.endDateAsString = pw.startDateAsString;
        if (pw.productObj.tcTime && pw.timePeriodAsString) params.timePeriodAsString = pw.timePeriodAsString;
  
      } else if (p.periodProduct) {
        if (pw.periodAsRange) params.periodAsRange = pw.periodAsRange;
     
      }
      
      if ((p.guestProduct || pw.type == "PACKAGE" ) && pw.guestCounts) params.guestCountsAsString = pw.guestCounts.guestCountsAsString;

      if (p.findIsPriceCustom) params.customAmountDouble = pw.customAmountDouble;

      if (pw.type) {
        params.type = pw.type;
        // if (pw.type == "PROVIDER") params.provider = true;
        // else    if (pw.type == "OVERVIEW") params.overview = true;
      } 

     
    } else {

      if (pw.periodAsRange) params.periodAsRange = pw.periodAsRange;
      if (pw.guestCountsAsString) params.guestCountsAsString = pw.guestCountsAsString;
    }

    // if (this.bas.envtest) console.log("getPwParams, params: ", params);


    if (pw.quantity !== undefined)  params.quantity = pw.quantity;
    if (pw.areaId)  params.area = pw.areaId || "";
    if (pw.prid)  params.prid = pw.prid;

    if (pw.campaignCode)  params.campaignCode = pw.campaignCode;



    return params;
  }

  updatePwFromPwValues(pw:any) {

    if (!pw) return pw;

    var params:any = this.getPwParams(pw);
    if (this.bas.envtest) console.log("updatePwFromPwValues, params: ", params);


  }

  updatePwFromPrev(pw:any, pwPrev:any) {

    if (!pw) return pw;
    if (this.bas.envtest) console.log("updatePwFromPrev, pwPrev: ", pwPrev);

    for (let childType of [ "accessories", "groupItems" ] ) {
      if (pwPrev[childType] === undefined) continue; 

      let childMap = MiscUtil.listToMap(pw[childType] || [], "productId"); 
      for (let childPrev of pwPrev[childType] || []) {
        let childNext = childMap[childPrev.productId];
        if (childNext && (childPrev.quantity != childNext?.quantity) ) {
          
          if(this.bas.envtest) console.log("updatePwFromPrev, updating childNext, name: "+childNext.product+": " + childNext.quantity + " -> " + childPrev.quantity);
          if (childNext.copyGuestCounts) {
            childNext.guestCounts = MiscUtil.clone(pw.guestCounts);
            childNext.quantity = pw.guestCounts.count ;
          } else {
            childNext.quantity =  childPrev.quantity;
          }
     
          this.bas.os.updatePwAmount(childNext);
          
        }
      }
    }

        
    if (pw.availablePoolUnits) {
      pw.jsonData = pw.jsonData || { };
      let apu = pw.availablePoolUnits.find((i:any) => i.id == pwPrev.jsonData?.poolUnitId);
      pw.jsonData.poolUnitId = apu?.id || '';
    }


  }


  updatePwObj(pw:any) {
    if (!pw) return pw;

    let prod = pw.productObj;

    this.bas.acs.updateProductObj(prod);

    pw.startDateAsJsDate = this.bas.ui.stringToDate(pw.startDateAsString);
    pw.endDateAsJsDate = this.bas.ui.stringToDate(pw.endDateAsString);
    pw.periodArray =[  pw.startDateAsJsDate,  pw.endDateAsJsDate ]



    if (pw.availablePoolUnits) {
      pw.jsonData = pw.jsonData || { };
      pw.jsonData.poolUnitId = '';
    }


      
    for (let gcIdx in pw.guestCategories) {
      if (pw.guestCounts.map[gcIdx] === undefined) pw.guestCounts.map[gcIdx] = 0; 
    }




    return pw;
  }


  updatePwError(pw:any) {
    let ui = this.bas.ui;
    let prod = pw.productObj;
		
    let errorMsg = "";

    if (pw) {

      if (    !pw.isOrderable 
          && !prod.productConfig.isRoute
          && prod.availabilityInfo
          && prod.findTypeExperience 
          && pw.selectableDatesNext !== undefined 
          && pw.selectableDatesNext.length == 0
          
          ) {
            errorMsg = ui.actrans("web.servlet.json.updatePrice.soldOut"); 
            pw.isSoldOut = true;
          
  
      } else if (pw.isStartBeforeStartDateThresholdMin) errorMsg = ui.actrans("web.servlet.json.updatePrice.startDateThresholdMin", [pw.startDateThresholdMin]);
      else if (pw.isStartAfterStartDateThresholdMax) errorMsg = ui.actrans("web.servlet.json.updatePrice.startDateThresholdMax", [pw.startDateThresholdMax]);
      else if (pw.isGuestCountLessThenMin) errorMsg = ui.actrans("web.servlet.json.updatePrice.guestCountLessThenMin", [prod.findMinGuests]);
      else if (pw.isGuestCountMoreThenMax) errorMsg = ui.actrans("web.servlet.json.updatePrice.guestCountMoreThenMax", [prod.findMaxGuests]);
      else if (prod.timeProduct && !prod.hasTimes) errorMsg = ui.actrans("web.servlet.json.updatePrice.noTimeAvailable"); 
      else if ( !pw.isOrderable) {
        errorMsg = ui.actrans("web.servlet.json.updatePrice.notAvailable"); 
              
      }
      else if (pw.canBeOrdered !== undefined && !pw.canBeOrdered) {
        errorMsg = ui.actrans("web.servlet.json.updatePrice.notAvailable"); 
        
      }
//				else if (!pw.isOrderable) errorMsg = ui.actrans("web.servlet.json.updatePrice.notAvailable"); 
      
      
      if (errorMsg == "" && !prod.timeProduct) {
        
        if (prod.findTypeLodging && pw.guestCounts.count > pw.cap) errorMsg = ui.actrans("web.servlet.json.updatePrice.cap");
        else if (pw.isLessThenMinDaysBooking) errorMsg = ui.actrans("web.servlet.json.updatePrice.lessThenMinDaysBooking", [pw.minDaysBooking]);
        else if (pw.isMoreThenMaxDaysBooking) errorMsg = ui.actrans("web.servlet.json.updatePrice.moreThenMaxDaysBooking", [pw.maxDaysBooking]);
        else if (pw.totalAmount === "") errorMsg = ui.actrans("web.servlet.json.updatePrice.priceIsNull");
        
      }

      if (errorMsg == "" && pw.findMkError != "") errorMsg = pw.findMkError;
    } 
    else {
      pw = { }
    }
    // errorMsg = ui.actrans("web.servlet.json.updatePrice.priceIsNull");
    

    
    if (errorMsg == "NULL") errorMsg = "";
    
    // if (errorMsg != "" && !p.availabilityInfo && aiContainer.length) errorMsg = "";
    
    pw.errorMsg = errorMsg;
    if (errorMsg != "") {

    }
    return pw;
  }


  //

  updateProducts():Promise<any> {

    return this.bas.ds.getFromJson(DATAID.ORDER_PRODUCTS, { actionType: "appService", action: "getProducts" }, true).then((json) => {
      if(this.bas.envtest) console.log("OrderService, updateProducts.then: ", json);
    });


  }

  getProducts(productType?:string):any[] {
    let products = this.bas.ds.getValue(DATAID.ORDER_PRODUCTS, { products: []}).products;
    if (!productType) return products; 

    return this.filter.transform(products, 
      { typeObj: { ptid: productType }}
    );

  }

  getProduct(id:number):any {
    let list:any[] = this.filter.transform(this.getProducts(), 
      { id: id }
    );
    return list.length ? list[0] : undefined;
  }

  getProductInfo(id:any):Promise<any> {
    return this.bas.ws.json({ actionType: "appService", action: "getProduct", id: id }).then((json) => {
     
      return json.product;
    });
  }



  updateUserOrders(coreData = false):Promise<any> {

    return this.bas.ds.getFromJson(DATAID.ORDER_ORDERS, { actionType: "appService", action: "getOrders", userOrders: true, coreData: coreData }, true).then((json) => {
      // if(this.bas.envtest) console.log("OrderService, updateUserOrders.then: ", json);

      // this.ws.ds.saveData
    });
  }

  getUserOrders(productType?:string, coreData = false):any[] {
    let orders = this.bas.ds.getValue(DATAID.ORDER_ORDERS, { orders: []}).orders || [];
    if (!productType) return orders; 

    // if(this.bas.envtest) console.log("OrderService.getOrders: " + orders.length + ", productType: " + productType);


    let transformObj = coreData
      ?  { product: { typeObj: { ptid: productType }} }
      :  { item0: { productObj: { typeObj: { ptid: productType }} } };


    let result = this.filter.transform(orders, transformObj );
    // if(this.bas.envtest) console.log("OrderService.getOrders: " + orders.length + "; result: " + result.length);
    return result;
  }

  getOrder(orderId:any, params:any = { }):Promise<any> {

    MiscUtil.extend(params, { actionType: "appService", action: "getOrder", id: orderId });
    return this.bas.ws.json(params).then((json) => {
     
      return json;
    });
  }


  changeOrder(biid:any, pw:any, extraParams:any, jsonData?:any, coreData = false):Promise<any> {
    
    // var user = this.ws.ds.get(DATAIDS.APP_LOGIN).user;
    var params:any = { 
      actionType: "appService", 
      action: "changeOrder", 
      // acceptEula: true,
      // userId: user.id
      biid: biid
    };

    MiscUtil.extend(params, extraParams);

    if (jsonData) params.jsonData = JSON.stringify(jsonData);


    MiscUtil.extend(params, {
      startAsIso8601: pw.startAsIso8601,
      endAsIso8601: pw.endAsIso8601
    }); 

    if(this.bas.envtest) console.log("OrderService.changeOrder: params: ", params);

    return this.bas.ws.json(params, { showLoading: true }).then((json:any) => {
      if(this.bas.envtest) console.log("OrderService.changeOrder.then, ", json);
      
      if (json.success) {
        return this.updateUserOrders(coreData);
      }
      return json;
    });
  }

  getPwCart(paramsInput:any, onSuccess?:any):Promise<any> {

    var params = { 
      actionType: "appService", 
      action: "getPwCart",
      // product: this.pw.productId,
      // startAsIso8601: this.pw.startAsIso8601, //this.ws.m(this.start).local().format(ISO8601woZ),
      // endAsIso8601: this.pw.endAsIso8601 //this.ws.m(this.end).local().format(ISO8601woZ)
    }
    MiscUtil.extend(params, paramsInput);

    return this.bas.ws.json(params).then((json) => {
      if(this.bas.envtest) console.log("OrderService.getPwCart.then, ");
      
      if (json.success) {
        
        let pw = json.cart.items[0];
        if (onSuccess) onSuccess(pw);
      }

    });

  }


  updateUser(paramsInput:any, onSuccess?:any):Promise<any> {
    var appLogin = this.bas.appLogin;
    
    var params:any = { 
      actionType: "app", 
      action: "updateUser",
    };
    for (let field in paramsInput) {
      params["user_" + field] = paramsInput[field];
      appLogin.user[field] = paramsInput[field];
    };

    // this.bas.ds.save(DATAID.APP_LOGIN, al); //TODO

    return this.bas.ws.json(params).then((json) => {
      if(this.bas.envtest) console.log("OrderService.updateUser.then");
      
      if (json.success) {
        if (onSuccess) onSuccess(json.user);
      }

      return json.user;

    });

  }


  checkIn(order:any, checkIn = true):Promise<any> {
    var appLogin = this.bas.appLogin;
    
    var params:any = { 
      actionType: "appService", 
      action: checkIn ? "checkIn" : "checkOut",
      id: order.id
    };

    return this.bas.ws.json(params).then((json) => {
      if(this.bas.envtest) console.log("OrderService.checkIn.then");
      
      if (json.success) {
        this.updateUserOrders();
      }

      return json.booking;
    });

  }



}

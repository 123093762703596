/* lib-components-core.modules.ts */
import { NgModule } from '@angular/core';


import { LibTemplateComponentComponent } from './lib-template-component/lib-template-component.component';

// form
import { BtDynamicFormControlComponent } from './form/bt-dynamic-form-control/bt-dynamic-form-control.component' 
import { BtFormRowComponent } from './form/bt-form-row/bt-form-row.component' 
import { BtMessageFieldComponent } from './form/bt-message-field/bt-message-field.component';

// order
import { BtPaymentComponent } from './order/bt-payment/bt-payment.component';
import { BtPwPeriodGuestsComponent } from './order/pw-period-guests/pw-period-guests.component';
import { BtSelectGuestsComponent } from './order/select-guests/select-guests.component';
import { BtCartAddComponent } from './order/cart-add/cart-add.component';
import { BtCartAddItemComponent } from './order/cart-add-item/cart-add-item.component';
import { BtCartAddFormComponent } from './order/cart-add-form/cart-add-form.component';

// ui
import { BtAlertComponent } from './ui/bt-alert/bt-alert.component';

import { BtColorPopoverComponent } from './ui/bt-color-popover/bt-color-popover.component';
import { BtContentRowComponent } from './ui/bt-content-row/bt-content-row.component';
import { BtDynamicModalComponent } from './ui/bt-dynamic-modal/bt-dynamic-modal.component';

import { BtGoogleMapsComponent } from './ui/bt-google-maps/bt-google-maps.component';


import { BtRowComponent } from './ui/bt-row/bt-row.component';
import { BtRow2Component } from './ui/bt-row-2/bt-row-2.component';
import { BtRow3Component } from './ui/bt-row-3/bt-row-3.component';
import { BtRow4Component } from './ui/bt-row-4/bt-row-4.component';

import { BtStylePopoverComponent } from './ui/bt-style-popover/bt-style-popover.component';
import { BtTableDataComponent } from './ui/bt-table-data/bt-table-data.component';
import { IframeComponent } from './ui/iframe/iframe.component';
import { PageWrapperComponent } from './ui/page-wrapper/page-wrapper.component'

// user
import { BtUserChangePasswordComponent } from './user/bt-user-change-password/bt-user-change-password.component';
import { BtUserChangeUsernameComponent } from './user/bt-user-change-username/bt-user-change-username.component';
import { BtUserDeleteUserComponent } from './user/bt-user-delete-user/bt-user-delete-user.component';
import { LoginComponent } from './user/login/login.component'
import { LoginCheckComponent } from './user/login-check/login-check.component'
import { UserMenuComponent } from './user/user-menu/user-menu.component'
import { UserPageWrapperComponent } from './user/user-page-wrapper/user-page-wrapper.component'

// --------------

@NgModule({
  declarations: [

  ],
  imports: [
    LibTemplateComponentComponent,

    BtDynamicFormControlComponent,
    BtFormRowComponent,
    BtMessageFieldComponent,
    
    // order
    BtPaymentComponent,
    BtPwPeriodGuestsComponent,
    BtSelectGuestsComponent,
    BtCartAddComponent,
    BtCartAddItemComponent,
    BtCartAddFormComponent,
    
    // ui
    BtAlertComponent,
    BtColorPopoverComponent,
    BtContentRowComponent,
    BtDynamicModalComponent,
    BtGoogleMapsComponent,
    
    BtRowComponent,
    BtRow2Component,
    BtRow3Component,
    BtRow4Component,
    
    BtStylePopoverComponent,
    BtTableDataComponent,
    IframeComponent,
    PageWrapperComponent,
    
    // user
    BtUserChangePasswordComponent,
    BtUserChangeUsernameComponent,
    BtUserDeleteUserComponent,
    LoginComponent,
    LoginCheckComponent,
    UserMenuComponent,
    UserPageWrapperComponent,

  ],
  exports: [

    LibTemplateComponentComponent,

    BtDynamicFormControlComponent,
    BtFormRowComponent,
    BtMessageFieldComponent,
    
    // order
    BtPaymentComponent,
    BtPwPeriodGuestsComponent,
    BtSelectGuestsComponent,
    BtCartAddComponent,
    BtCartAddItemComponent,
    BtCartAddFormComponent,
    
    // ui
    BtAlertComponent,
    BtColorPopoverComponent,
    BtContentRowComponent,
    BtDynamicModalComponent,
    BtGoogleMapsComponent,
    
    BtRowComponent,
    BtRow2Component,
    BtRow3Component,
    BtRow4Component,
    
    BtStylePopoverComponent,
    BtTableDataComponent,
    IframeComponent,
    PageWrapperComponent,
    
    // user
    BtUserChangePasswordComponent,
    BtUserChangeUsernameComponent,
    BtUserDeleteUserComponent,
    LoginComponent,
    LoginCheckComponent,
    UserMenuComponent,
    UserPageWrapperComponent,


  ]
})
export class LibComponentsCoreModule {}
